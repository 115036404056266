/* I n f o */
/**
 * A simple library that aims to make Firebase database usage easier to use
 * and faster to implement into new projects.
 */

const firebase = require('firebase/app')
require('firebase/auth')
require('firebase/firestore')
require('firebase/storage')

/* G l o b a l   V a r i a b l e s */
const config = {
  apiKey: process.env.FIREBASE_APIKEY,
  projectId: process.env.FIREBASE_PROJECTID,
  authDomain: process.env.FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.FIREBASE_DATABASEURL,
  storageBucket: process.env.FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGINGSENDERID,
}
firebase.initializeApp(config)
// Store global ref to 'db'
const db = firebase.firestore()
window.db = db

let secondaryAppConfig = {}
let secondary
if (process.env.SECOND_DATABASE_APIKEY) {
  secondaryAppConfig = {
    apiKey: process.env.SECOND_DATABASE_APIKEY,
    authDomain: process.env.SECOND_DATABASE_AUTHDOMAIN,
    databaseURL: process.env.SECOND_DATABASE_DATABASEURL,
    projectId: process.env.SECOND_DATABASE_PROJECTID,
    storageBucket: process.env.SECOND_DATABASE_STORAGEBUCKET,
    messagingSenderId: process.env.SECOND_DATABASE_MESSAGINGSENDERID,
  }
  secondary = firebase.initializeApp(secondaryAppConfig, 'secondary')
}

// var storagePathURL = 'https://storage.googleapis.com/' + config.storageBucket + '/';
// var storage = firebase.storage();
// var storageRef = storage.ref();
let user
let firebaseInitialised = false
firebase.auth().onAuthStateChanged(function(e) {
  if (e) {
    // set the user if someone is already logged in
    user = firebase.auth().currentUser
    // unhideMembersNavSections();
    // If onLoad while logged in - useful for setting a username to show on all pages
  } else {
    user = undefined
  }
  firebaseInitialised = true
  if (window.onFirebaseLoad) window.onFirebaseLoad()
})

/* F u n c t i o n s */
/*- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
	loadFromFirebasePath(path)
	
	Info:
	returns a *PROMISE OBJECT* containing the object
	
	Example usage:
	loadFromFirebasePath('/franchisee/' + user.uid).then(function(obj) {
		appendHTML('#testContainer', '<p>' + obj.collectionCodeID + '</p>');
		appendHTML('#testContainer', '<p>' + obj.name + '</p>');
	});
	- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -*/
window.loadFromFirebasePath = loadFromFirebasePath
function loadFromFirebasePath(path) {
  try {
    return firebase
      .database()
      .ref(path)
      .once('value')
      .then(
        function(snapshot) {
          return snapshot.val()
        },
        function(error) {
          throw 'There was an error reading data'
        }
      )
  } catch (err) {
    console.error(err)
    console.trace('^')
  }
}
window.loadFromSecondDatabase = loadFromSecondDatabase
function loadFromSecondDatabase(path) {
  try {
    return secondary
      .database()
      .ref(path)
      .once('value')
      .then(
        function(snapshot) {
          return snapshot.val()
        },
        function(error) {
          throw 'There was an error reading data'
        }
      )
  } catch (err) {
    console.log(err)
    console.trace('^')
  }
}

/*- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
	saveToFirebasePath(path, object)
	
	Info:
	saves new items into the specified path
	
	Example usage:
	saveToFirebasePath('/franchisee/' + user.uid, {
		'thing': 'this is new',
		'other thing': 'something else'
	});
	- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -*/
window.saveToFirebasePath = saveToFirebasePath
function saveToFirebasePath(path, object) {
  try {
    return firebase
      .database()
      .ref(path)
      .update(object)
      .then(
        function() {
          //console.log('Successfully wrote to ' + path)
        },
        function(err) {
          throw err
        }
      )
  } catch (err) {
    console.error(err)
    console.trace('^')
  }
}
window.saveToSecondDatabase = saveToSecondDatabase
function saveToSecondDatabase(path, object) {
  try {
    return secondary
      .database()
      .ref(path)
      .update(object)
      .then(
        function() {
          //console.log('Successfully wrote to ' + path)
        },
        function(err) {
          throw err
        }
      )
  } catch (err) {
    console.error(err)
    console.trace('^')
  }
}
window.saveToFirebasePathSET = saveToFirebasePathSET
function saveToFirebasePathSET(path, object) {
  try {
    return firebase
      .database()
      .ref(path)
      .set(object)
      .then(
        function() {
          //console.log('Successfully wrote to ' + path)
        },
        function(err) {
          throw err
        }
      )
  } catch (err) {
    console.error(err)
    console.trace('^')
  }
}
window.saveToSecondDatabaseSET = saveToSecondDatabaseSET
function saveToSecondDatabaseSET(path, object) {
  try {
    return secondary
      .database()
      .ref(path)
      .set(object)
      .then(
        function() {
          //console.log('Successfully wrote to ' + path)
        },
        function(err) {
          throw err
        }
      )
  } catch (err) {
    console.error(err)
    console.trace('^')
  }
}

/*- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
	saveToFirebaseUser(object)
	
	Info:
	saves new user information - displayName photoURL email password
	
	Example usage:
	saveToFirebaseUser({
		'displayName': 'this is new'
	});
	- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -*/
window.saveToFirebaseUser = saveToFirebaseUser
function saveToFirebaseUser(object) {
  try {
    return user.updateProfile(object).then(
      function() {
        //console.log('Successfully updated user info ')
      },
      function(err) {
        throw err
      }
    )
  } catch (err) {
    console.error(err)
    console.trace('^')
  }
}

/*- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
	saveImageToFirebaseStorage(path, file, filename)
	
	Info:
	Saves an image to Firebase storage and returns the url
	
	Example usage:
	var imageFile = document.getElementById("franchiseImage").files[0];
	saveImageToFirebaseStorage('editCollectionCodeID', imageFile, 'exampleFileName').then(function(url) {
		console.log(url.a.downloadURLs[0]);
	});
	- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -*/
window.saveImageToFirebaseStorage = saveImageToFirebaseStorage
function saveImageToFirebaseStorage(path, file, filename) {
  try {
    if (!(file instanceof Blob)) {
      throw 'You have not selected a blob!'
    }
    var fileExtension = 'png'
    // Create the file metadata
    var metadata = {
      contentType: 'image/' + fileExtension,
    }
    // Upload file and metadata to the object 'images/mountains.jpg'
    var uploadTask = storageRef.child(path + '/' + filename + '.' + fileExtension).put(file, metadata)
    return uploadTask
  } catch (err) {
    console.error(err)
    console.trace('^')
  }
}

/*- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
	appendHTML('#element', 'html')
	
	Info: Appends html to an element via id, or appends html to all
	elements that have Specified class.
	
	Example usage:
	appendHTML('#singleArea', '<p>' + obj.name + '</p>');
	appendHTML('.multipleAreas', '<p>' + obj.name + '</p>');
	- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -*/
window.appendHTML = appendHTML
function appendHTML(a, b) {
  try {
    if (a == undefined || b == undefined) {
      throw 'Something has not been defined! -> appendHTML(elementToAppendTo, HTMLToAppend);'
    } else {
      if (a.startsWith('#')) {
        var elementToAppendTo = document.querySelector(a)
        var newInnerElement = document.createElement('div')
        // Create an element and append it
        newInnerElement.innerHTML = b
        elementToAppendTo.appendChild(newInnerElement)
        // Remove the parent div
        var fragment = document.createDocumentFragment()
        while (newInnerElement.firstChild) {
          fragment.appendChild(newInnerElement.firstChild)
        }
        newInnerElement.parentNode.replaceChild(fragment, newInnerElement)
      } else {
        if (a.startsWith('.')) {
          var elementToAppendTo = document.querySelectorAll(a)
          for (i = 0; i < elementToAppendTo.length; i++) {
            var newInnerElement = document.createElement('div')
            // Create an element and append it
            newInnerElement.innerHTML = b
            elementToAppendTo[i].appendChild(newInnerElement)
            // Remove the parent div
            var fragment = document.createDocumentFragment()
            while (newInnerElement.firstChild) {
              fragment.appendChild(newInnerElement.firstChild)
            }
            newInnerElement.parentNode.replaceChild(fragment, newInnerElement)
          }
        } else {
          throw 'appendHTML: DOM "' + a + '" has not been defined as an ID or class.'
        }
      }
    }
  } catch (err) {
    console.error(err)
    console.trace('^')
  }
}

function getIdToken() {
  console.log('getidtoken')
  return waitForFirebaseInit()
    .then(_ => {
      if (!firebase.auth().currentUser) return null
      return firebase.auth().currentUser.getIdToken()
    })
    .catch(err => null)
}

function getIdTokenResult() {
  return waitForFirebaseInit()
    .then(_ => {
      if (!firebase.auth().currentUser) return null
      return firebase.auth().currentUser.getIdTokenResult()
    })
    .catch(err => null)
}

function callFunction(name, method, data) {
  return getIdToken().then(idToken => {
    const headers = {}
    if (idToken) {
      headers['Content-type'] = 'application/json'
      headers.Authorization = 'Bearer ' + idToken
    }
    const url = process.env.FIREBASE_FUNCTIONS_BASE_URL + '/' + name
    const options = { method, headers, body: JSON.stringify(data) }
    return fetch(url, options).then(response => {
      if (response.status === 403) window.location = '/'
      return response.json()
    })
  })
}

function getCollection(collection) {
  return db
    .collection(collection)
    .get()
    .then(snapshot => {
      if (!snapshot.docs.length) {
        return []
      }
      return snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
    })
}

const getRecordsWhere = (collection, fieldPath, operator, value) => {
  return db
    .collection(collection)
    .where(fieldPath, operator, value)
    .get()
    .then(querySnapshot => {
      const results = []
      querySnapshot.forEach(doc => results.push({ ...doc.data(), id: doc.id }))
      return results
    })
}

function getSubcollection(collection, collectionId, subcollection) {
  return db
    .collection(collection)
    .doc(collectionId)
    .collection(subcollection)
    .get()
    .then(snapshot => {
      if (!snapshot.docs.length) {
        return []
      }
      return snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
    })
}

function getFromCollection(collection, id) {
  // Alias for getRecord
  return getRecord(collection, id)
}

function getRecord(collection, id) {
  return db
    .collection(collection)
    .doc(id)
    .get()
    .then(doc => {
      if (!doc.exists) return null
      return { ...doc.data(), id: doc.id }
    })
    .catch(err => {
      console.info(err)
      return null
    })
}

function getFromSubcollection(collection, id, subcollection, subId) {
  return db
    .collection(collection)
    .doc(id)
    .collection(subcollection)
    .doc(subId)
    .get()
    .then(doc => {
      return { ...doc.data(), id: doc.id }
    })
    .catch(err => {
      console.info(err)
      return null
    })
}

function waitForFirebaseInit() {
  return new Promise(resolve => {
    const check = _ => {
      if (firebaseInitialised) {
        resolve(user) // this used to be 'window.user' but that was undefined
      } else {
        setTimeout(check, 200)
      }
    }
    setTimeout(check, 200)
  })
}

function updateRecord(collection, id, dataToPut) {
  return db
    .collection(collection)
    .doc(id)
    .update(dataToPut)
    .catch(err => {
      console.error(err)
      throw err
    })
}

const updateRecordInSubcollection = (collection, id, subCollection, subId, dataToPut) => {
  return db
    .collection(collection)
    .doc(id)
    .collection(subCollection)
    .doc(subId)
    .update(dataToPut)
    .catch(err => {
      console.error(err)
      throw err
    })
}

function setRecord(collection, id, dataToPut) {
  return db
    .collection(collection)
    .doc(id)
    .set(dataToPut)
    .catch(err => {
      console.error(err)
      throw err
    })
}

const addRecord = (collection, newObj) => {
  return db
    .collection(collection)
    .add(newObj)
    .then(ref => ref.id)
    .catch(err => {
      console.error(err)
      throw err
    })
}

const addRecordToSubcollection = (collection, id, subCollection, newObj) => {
  return db
    .collection(collection)
    .doc(id)
    .collection(subCollection)
    .add(newObj)
    .then(ref => ({ ...newObj, id: ref.id }))
    .catch(err => {
      console.error(err)
      throw err
    })
}

const deleteRecord = (collection, id) => {
  return db
    .collection(collection)
    .doc(id)
    .delete()
    .catch(err => {
      console.error(err)
      throw err
    })
}

const deleteRecordFromSubcollection = (collection, id, subCollection, subId) => {
  return db
    .collection(collection)
    .doc(id)
    .collection(subCollection)
    .doc(subId)
    .delete()
    .catch(err => {
      console.error(err)
      throw err
    })
}

function logout() {
  return firebase.auth().signOut()
}

function login(emailAddress, password) {
  return firebase.auth().signInWithEmailAndPassword(emailAddress, password)
}

function uploadFile(fileName, fileType, fileContents) {
  const metaData = { contentType: fileType }
  const storageRef = firebase.storage().ref()
  const file = storageRef.child(fileName)
  return file.put(fileContents, metaData).then(_ => file.getDownloadURL())
}

function subscribeToSubcollection(collection, id, subCollection, callback) {
  return db
    .collection(collection)
    .doc(id)
    .collection(subCollection)
    .onSnapshot(
      querySnapshot => callback(querySnapshot, id),
      err => console.error(err)
    )
}

function subscribeToCollection(collection, callback) {
  return db.collection(collection).onSnapshot(
    querySnapshot => callback(querySnapshot),
    err => console.error(err)
  )
}

function subscribeToDoc(collection, doc, callback) {
  return db
    .collection(collection)
    .doc(doc)
    .onSnapshot(
      querySnapshot => callback(querySnapshot),
      err => console.error(err)
    )
}

function subscribeToCollectionWhere(collection, fieldPath, operator, value, callback) {
  return db
    .collection(collection)
    .where(fieldPath, operator, value)
    .onSnapshot(
      querySnapshot => callback(querySnapshot),
      err => console.error(err)
    )
}

function sendPasswordResetEmail(email) {
  return firebase.auth().sendPasswordResetEmail(email)
}

const utils = {
  deleteRecordFromSubcollection,
  updateRecordInSubcollection,
  saveImageToFirebaseStorage,
  subscribeToCollectionWhere,
  subscribeToSubcollection,
  addRecordToSubcollection,
  saveToSecondDatabaseSET,
  loadFromSecondDatabase,
  sendPasswordResetEmail,
  saveToFirebasePathSET,
  subscribeToCollection,
  loadFromFirebasePath,
  saveToSecondDatabase,
  getFromSubcollection,
  waitForFirebaseInit,
  saveToFirebasePath,
  saveToFirebaseUser,
  getFromCollection,
  getIdTokenResult,
  getSubcollection,
  getRecordsWhere,
  getCollection,
  callFunction,
  updateRecord,
  deleteRecord,
  getIdToken,
  uploadFile,
  appendHTML,
  addRecord,
  getRecord,
  setRecord,
  logout,
  login,
  subscribeToDoc,
}

window.fb = utils
window.firebaseUtils = utils

module.exports = utils

window.firebase = firebase
